import { Navigate, useRoutes } from 'react-router-dom';

// import { PATH_AFTER_LOGIN } from 'src/config-global';
import { authRoutes } from './auth';
import { mainRoutes } from './main';
import NotFoundPage from "../../pages/404";
import { dashboardRoutes } from './dashboard';

// ----------------------------------------------------------------------



export default function Router () {
  return useRoutes([
    // {
    //   path: '/',
    //   element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    // },

    ...authRoutes,
    dashboardRoutes,
    ...mainRoutes,

    { path: '404', element: <NotFoundPage /> },

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
