import { useMemo } from 'react';

import { paths } from 'src/routes/paths';

import { useTranslate } from 'src/locales';

import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  product: icon('ic_product'),
  ecommerce: icon('ic_ecommerce'),
  settings: icon('ic_setting'),

};

// ----------------------------------------------------------------------

export function useNavData () {
  const { t } = useTranslate();

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: t('overview'),
        items: [
          {
            title: t('Dashboard'),
            path: "/",
            icon: ICONS.ecommerce,
          },
        ],
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        // subheader: t('inventory'),
        items: [
          // PRODUCT
          {
            title: t('inventory'),
            path: paths.dashboard.listing.root,
            icon: ICONS.product,
            children: [
              { title: t('My Listings'), path: paths.dashboard.listing.root },
              { title: t('create listing'), path: paths.dashboard.listing.new },
            ],
          },
        ],
      },
      {
        // subheader: t('management'),
        items: [
          // PRODUCT
          {
            title: t('management'),
            path: paths.management.stores,
            icon: ICONS.settings,
            children: [
              { title: t('My Stores'), path: paths.management.stores },
              { title: t('Store settings'), path: paths.management.polices },
            ],
          },


        ],
      },

    ],
    [t]
  );

  return data;
}
