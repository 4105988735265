import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';

import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// Dashboard
const OverviewEcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
// PRODUCT
const ListingListPage = lazy(() => import('src/pages/listing/list'));
const ListingCreatePage = lazy(() => import('src/pages/listing/new'));
const ListingEditPage = lazy(() => import('src/pages/listing/edit'));
// Management
const StoresPage = lazy(() => import('src/pages/management/stores'));
const PoliciesPage = lazy(() => import('src/pages/management/policies'));
// Account
const AccountPage = lazy(() => import('src/pages/account/AccountPage'));
// PERMISSION 	
const PermissionDeniedPage = lazy(() => import('src/pages/permission'));

// ----------------------------------------------------------------------

export const dashboardRoutes =
{
  path: '/',
  element: (
    <AuthGuard>
      <DashboardLayout>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    </AuthGuard>
  ),
  children: [
    { path: '/', element: <OverviewEcommercePage /> },
    { path: "stores", element: <StoresPage /> },
    { path: "policies", element: <PoliciesPage /> },
    { path: "account", element: <AccountPage /> },
    {
      path: 'listing',
      children: [
        { element: <ListingListPage />, index: true },
        { path: 'list', element: <ListingListPage /> },
        { path: 'new', element: <ListingCreatePage /> },
        { path: ':id/edit', element: <ListingEditPage /> },
      ],
    },

    { path: 'permission', element: <PermissionDeniedPage /> },

  ],
};


