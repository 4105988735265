// ----------------------------------------------------------------------

export const PRODUCT_GENDER_OPTIONS = [
  { label: 'Men', value: 'M' },
  { label: 'Women', value: 'W' },
  { label: 'Kids', value: 'K' },
  { label: 'Unisex', value: 'U' },
];

export const PRODUCT_CATEGORY_OPTIONS = ['Shose', 'Apparel', 'Accessories'];

export const PRODUCT_RATING_OPTIONS = ['up4Star', 'up3Star', 'up2Star', 'up1Star'];

export const PRODUCT_COLOR_OPTIONS = [
  '#00AB55',
  '#000000',
  '#FFFFFF',
  '#FFC0CB',
  '#FF4842',
  '#1890FF',
  '#94D82D',
  '#FFC107',
];

export const PRODUCT_COLOR_NAME_OPTIONS = [
  { value: 'red', label: 'Red' },
  { value: 'blue', label: 'Blue' },
  { value: 'cyan', label: 'Cyan' },
  { value: 'green', label: 'Green' },
  { value: 'yellow', label: 'Yellow' },
  { value: 'violet', label: 'Violet' },
  { value: 'black', label: 'Black' },
  { value: 'white', label: 'White' },
];

export const PRODUCT_SIZE_OPTIONS = [
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '8.5', label: '8.5' },
  { value: '9', label: '9' },
  { value: '9.5', label: '9.5' },
  { value: '10', label: '10' },
  { value: '10.5', label: '10.5' },
  { value: '11', label: '11' },
  { value: '11.5', label: '11.5' },
  { value: '12', label: '12' },
  { value: '13', label: '13' },
];

export const PRODUCT_STOCK_OPTIONS = [
  { value: 'in stock', label: 'In stock' },
  { value: 'low stock', label: 'Low stock' },
  { value: 'out of stock', label: 'Out of stock' },
];

export const PRODUCT_PUBLISH_OPTIONS = [
  {
    value: 'published',
    label: 'Published',
  },
  {
    value: 'draft',
    label: 'Draft',
  },
];

export const PRODUCT_SORT_OPTIONS = [
  { value: 'featured', label: 'Featured' },
  { value: 'newest', label: 'Newest' },
  { value: 'priceDesc', label: 'Price: High - Low' },
  { value: 'priceAsc', label: 'Price: Low - High' },
];

export const PRODUCT_CATEGORY_GROUP_OPTIONS = [
  {
    group: 'Clothing',
    classify: ['Shirts', 'T-shirts', 'Jeans', 'Leather', 'Accessories'],
  },
  {
    group: 'Tailored',
    classify: ['Suits', 'Blazers', 'Trousers', 'Waistcoats', 'Apparel'],
  },
  {
    group: 'Accessories',
    classify: ['Shoes', 'Backpacks and bags', 'Bracelets', 'Face masks'],
  },
];


export const PRODUCT_CONDITION_OPTIONS = [

  { value: null, label: null },
  { value: 'NEW', label: 'NEW' },
  { value: 'LIKE_NEW', label: 'LIKE_NEW' },
  { value: 'NEW_WITH_DEFECTS', label: 'NEW_WITH_DEFECTS' },
  { value: 'USED_EXCELLENT', label: 'USED_EXCELLENT' },
  { value: 'USED_VERY_GOOD', label: 'USED_VERY_GOOD' },
  { value: 'USED_GOOD', label: 'USED_GOOD' },
  { value: 'USED_ACCEPTABLE', label: 'USED_ACCEPTABLE' },
];

export const PRODUCT_CHECKOUT_STEPS = ['Cart', 'Billing & address', 'Payment'];

export const EBAY_MARKETPLACES = [{
  "EBAY_AT": {
    "categoryTreeId": "16"
  },
  "EBAY_AU": {
    "categoryTreeId": "15"
  },
  "EBAY_BE": {
    "categoryTreeId": "123"
  },
  "EBAY_CA": {
    "categoryTreeId": "2"
  },
  "EBAY_CH": {
    "categoryTreeId": "193"
  },

  "EBAY_DE": {
    "categoryTreeId": "77"
  },
  "EBAY_ES": {
    "categoryTreeId": "186"
  },
  "EBAY_FR": {
    "categoryTreeId": "71"
  },
  "EBAY_GB": {
    "categoryTreeId": "3"
  },
  "EBAY_HK": {
    "categoryTreeId": "201"
  },
  "EBAY_IE": {
    "categoryTreeId": "205"
  },
  "EBAY_IN": {
    "categoryTreeId": "203"
  },
  "EBAY_IT": {
    "categoryTreeId": "101"
  },
  "EBAY_MY": {
    "categoryTreeId": "207"
  },
  "EBAY_MOTORS_US": {
    "categoryTreeId": "100"
  },
  "EBAY_NL": {
    "categoryTreeId": "146"
  },
  "EBAY_PH": {
    "categoryTreeId": "211"
  },
  "EBAY_PL": {
    "categoryTreeId": "212"
  },
  "EBAY_RU": {
    "categoryTreeId": "215"
  },
  "EBAY_SG": {
    "categoryTreeId": "216"
  },
  "EBAY_US": {
    "categoryTreeId": "0"
  }
}
];

const PACKAGE_TYPE = [
  { value: 'letter', label: 'Letter' },
  { value: 'flat', label: 'Flat' },
  { value: 'parcel', label: 'Parcel' },
  { value: 'thick_envelope', label: 'Thick envelope' },
  { value: 'USPS_LARGE_PACK', label: 'Package' },
  { value: 'VERY_LARGE_PACK', label: 'Large package' },
];


export const EBAY_WHO_DID = [
  { value: null, label: null },
  { value: 'i_did', label: 'I did' },
  { value: 'collective', label: 'A member of my shop' },
  { value: 'someone_else', label: 'Another company or person' },
];

export const EBAY_WHEN_MADE = [
  { value: null, label: null },
  { value: 'made_to_order', label: 'Made to order' },
  { value: '2020_2024', label: '2020 - 2024' },
  { value: '2010_2019', label: '2010 - 2019' },
  { value: '2005_2009', label: '2005 - 2009' },
  // { value: 'before_2005', label: '1990 - 1999' },
  { value: '2000_2004', label: '2000 - 2004' },
  { value: '1990s', label: '1990s' },
  { value: '1980s', label: '1980s' },
  { value: '1970s', label: '1970s' },
  { value: '1960s', label: '1960s' },
  { value: '1950s', label: '1950s' },
  { value: '1940s', label: '1940s' },
  { value: '1930s', label: '1930s' },
  { value: '1920s', label: '1920s' },
  { value: '1910s', label: '1910s' },
  { value: '1900s', label: '1900s' },
  { value: '1800s', label: '1800s' },
  { value: '1700s', label: '1700s' },
  { value: 'before_1700', label: 'Before 1700' },
];

export const ETSY_TYPE = [
  { value: null, label: null },
  { value: 'physical', label: 'Physical' },
  { value: 'digital', label: 'Digital' },
  { value: 'supply', label: 'Supply' },
];

