import PropTypes from 'prop-types';
import { useMemo, useEffect, useReducer, useCallback } from 'react';
import {
  getAuth,
  signOut,
  signInWithPopup,
  onAuthStateChanged,
  GoogleAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from 'firebase/auth';

import { firebaseApp } from './lib';
import { AuthContext } from './auth-context';
import { getUser, createUser, updateUser } from '../../redux/slices/user';



const AUTH = getAuth(firebaseApp);

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  return state;
};

export function AuthProvider ({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const syncUserWithDatabase = useCallback(async (firebaseUser) => {
    try {
      let userProfile = await getUser();
      console.log('userProfile', userProfile);

      if (!userProfile) {
        // Create new user in database if not exists
        userProfile = await createUser({
          uid: firebaseUser.uid,
          email: firebaseUser.email,
          displayName: firebaseUser.displayName,
          photoURL: firebaseUser.photoURL,
          emailVerified: firebaseUser.emailVerified,
          lastLoginAt: new Date().toISOString(),
        });
      } else {
        // Update existing user
        userProfile = await updateUser(
          {
            emailVerified: firebaseUser.emailVerified,
            lastLoginAt: new Date().toISOString(),

          }
        );
      }

      return {
        ...firebaseUser,
        ...userProfile,
      };
    } catch (error) {
      console.error('Error syncing user with database:', error);
      return null;
    }
  }, []);

  const initialize = useCallback(() => {
    onAuthStateChanged(AUTH, async (user) => {
      if (user) {
        if (user.emailVerified) {
          const syncedUser = await syncUserWithDatabase(user);
          console.log('syncedUser', syncedUser);

          dispatch({
            type: 'INITIAL',
            payload: { user: syncedUser },
          });
        } else {
          dispatch({
            type: 'INITIAL',
            payload: { user: null },
          });
        }
      } else {
        dispatch({
          type: 'INITIAL',
          payload: { user: null },
        });
      }
    });
  }, [syncUserWithDatabase]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const login = useCallback(async (email, password) => {
    const userCredential = await signInWithEmailAndPassword(AUTH, email, password);
    await syncUserWithDatabase(userCredential.user);
  }, [syncUserWithDatabase]);

  const loginWithGoogle = useCallback(async () => {
    const provider = new GoogleAuthProvider();
    const userCredential = await signInWithPopup(AUTH, provider);
    await syncUserWithDatabase(userCredential.user);
  }, [syncUserWithDatabase]);

  const register = useCallback(async (email, password, firstName, lastName) => {
    const userCredential = await createUserWithEmailAndPassword(AUTH, email, password);
    await sendEmailVerification(userCredential.user);
    await createUser({
      uid: userCredential.user.uid,
      email,
      displayName: `${firstName} ${lastName}`,
      emailVerified: false,
      lastLoginAt: new Date().toISOString(),
    });
  }, []);

  const logout = useCallback(async () => {
    await signOut(AUTH);
  }, []);

  const forgotPassword = useCallback(async (email) => {
    await sendPasswordResetEmail(AUTH, email);
  }, []);

  const checkAuthenticated = state.user?.emailVerified ? 'authenticated' : 'unauthenticated';
  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'firebase',
      loading: status === 'loading',
      isAuthenticated: status === 'authenticated',
      isUnauthenticated: status === 'unauthenticated',
      login,
      logout,
      register,
      forgotPassword,
      loginWithGoogle,
    }),
    [
      status,
      state.user,
      login,
      logout,
      register,
      forgotPassword,
      loginWithGoogle,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};