import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import listingsReducers from './slices/listing';
import managementReducers from './slices/management';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};


const appReducer = combineReducers({
  listings: listingsReducers,
  management: managementReducers,


});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    storage.removeItem('persist:root')
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export { rootPersistConfig, rootReducer };
