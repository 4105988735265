import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import MainLayout from 'src/layouts/main';

import { SplashScreen } from 'src/components/loading-screen';
// 404
const NotFoundPage = lazy(() => import('src/pages/404'));


// ----------------------------------------------------------------------



export const mainRoutes = [
  {
    element: (
      <MainLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </MainLayout>
    ),
    children: [
      // { path: '404', element: <NotFoundPage /> },

    ],
  },

];
